<template>
  <div>
    <loading-bar :value="loading"/>
    <v-container class="pa-0" fluid v-if="vblEvent">
      <v-row dense>
        <v-col cols="12" class="py-0">
          <!-- ORGANIZATION -->
          <v-card v-if="organizations.length > 1000">
            <v-toolbar dense flat :color="disabled ? '' : 'color1 color1Text--text'">
              <v-toolbar-title>
                Host
              </v-toolbar-title>
            </v-toolbar>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12">
                  <v-autocomplete
                    :items="organizations"
                    v-model="vblEvent.organization"
                    label="Choose a host"
                    item-text="name"
                    item-value="id"
                    return-object
                    :disabled="disabled || organizations.length === 1"
                    color="color3"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
        <v-col cols="12" class="py-0" order="1">
          <!-- SETTINGS -->
          <v-card flat tile>
            <v-toolbar dense flat :color="disabled ? 'grey lighten-3 grey--text' : 'color1 color1Text--text'">
              <v-toolbar-title>
                Quick Settings
              </v-toolbar-title>
            </v-toolbar>
            <v-container fluid>
              <v-row dense>
                <v-col cols=12>
                  <v-switch
                    label="Published"
                    :loading="publishing"
                    v-model="published"
                    hide-details
                    color="success"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <!-- DETAILS -->
          <v-card flat tile>
            <v-toolbar dense flat :color="disabled ? 'grey lighten-3 grey--text' : 'color1 color1Text--text'">
              <v-toolbar-title>
                {{vblEvent.type | capFirst}} Details
              </v-toolbar-title>
            </v-toolbar>
            <v-container fluid>
              <!-- NAME & 1 day checkbox -->
              <v-row dense>
                <v-col cols="12" sm="9">
                  <v-text-field
                    label="Event Title*"
                    v-model="vblEvent.title"
                    :disabled="disabled"
                    color="color3"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    label="One day"
                    v-model="oneDay"
                    hide-details
                    :disabled="disabled"
                    readonly
                    @click.stop="toggleOneDay"
                    color="color3"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <!-- DATE & TIMES -->
              <v-row dense>
                <!-- START & END DATE -->
                <v-col cols="12" sm="6">
                  <date-time-picker
                    :datetime.sync="vblEvent.dtStart"
                    label="Start"
                    :disabled="disabled"
                    type="date"
                  ></date-time-picker>
                </v-col>
                <v-col cols="12" sm="6">
                  <date-time-picker
                    v-if="!oneDay"
                    :datetime.sync="vblEvent.dtEnd"
                    label="End"
                    :disabled="disabled"
                    type="date"
                  ></date-time-picker>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <div class="grey lighten-3 mt-3 pa-2">The title tag will be part of the url and you should only use letters, numbers and _ with NO spaces</div>
                  <v-text-field
                    label="Event Title Tag*"
                    v-model="vblEvent.titleTag"
                    :disabled="disabled"
                    color="color3"
                    persistent-hint
                    :hint="vblEvent.id > 0 ? 'Changing this will change change the URL - DO NOT CHANGE if you have published the event' : ''"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
        <v-col cols="12" class="py-0" order="4">
          <!-- COVERPHOTO -->
          <v-card flat tile>
            <v-toolbar dense flat :color="disabled ? 'grey lighten-3 grey--text' : 'color1 color1Text--text'">
              <v-toolbar-title>
                Cover Photo{{vblEvent.coverPhotos.length > 1 ? 's': ''}}
              </v-toolbar-title>
            </v-toolbar>
            <v-tabs
              :background-color="disabled ? '#f5f5f5' : 'color2'"
              :slider-color="disabled ? 'black' : 'color3'"
              v-model="coverPhotoI"
            >
              <v-tab
                v-for="photo in vblEvent.coverPhotos"
                :key="photo.order"
                :class="{'color2Text--text': !disabled}"
              >
                Photo {{photo.order}}
              </v-tab>
            </v-tabs>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" v-if="coverPhoto">
                  <v-img
                    :position="coverPhoto.position"
                    :src="coverPhoto.url"
                    :cover="!coverPhoto.contain"
                    :contain="coverPhoto.contain"
                    :height="coverPhoto.contain ? null : $vuetify.breakpoint.xs ? 250 : 360"
                    :max-height="coverPhoto.contain ? $vuetify.breakpoint.xs ? 250 : 360 : null"
                  >
                    <div :class="{'block': disabled}"></div>
                    <v-row class="fill-height"   align="end">
                      <v-col class="text-right" cols="12" >
                        <v-btn
                          v-if="coverPhotoI > 0"
                          color="white red--text"
                          fab
                          small
                          @click.stop="deletePhoto(coverPhotoI)"
                        >
                          <v-icon>fas fa-trash</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-img>
                </v-col>
              </v-row>
              <v-row dense v-if="coverPhoto">
                <v-col class="text-right" cols="4" >
                  <v-checkbox
                    label="contain"
                    v-model="coverPhoto.contain"
                    class="shrink"
                    hide-details
                    :disabled="disabled"
                    color="color3"
                  ></v-checkbox>
                </v-col>
                <v-col class="text-right" cols="8" v-if="!coverPhoto.contain">
                  <v-combobox
                    class="shrink"
                    :items="backPositions"
                    v-model="coverPhoto.position"
                    label="Focal point"
                    style="z-index: 3"
                    :disabled="disabled"
                    color="color3"
                    item-color="color3"
                    hint="CSS background-position for the image"
                    persistent-hint
                  ></v-combobox>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" v-if="!disabled">
                  <v-autocomplete
                    v-if="user.pages.length > 1"
                    :items="user.pages"
                    item-text="name"
                    return-object
                    v-model="selectedPage"
                    label="Organization"
                    hint="Choose and organization to select and image from"
                    persistent-hint
                    color="color3"
                    item-color="color3"
                  ></v-autocomplete>

                  <template v-if="selectedUsername">
                    <label class="v-label v-label--active theme--light mt-4">Choose a photo</label>
                    <image-library
                      v-if="selectedUsername"
                      :username="selectedUsername"
                      single-row
                      no-preview
                      :img-height="36 * 4"
                      :img-width="64 * 4"
                      @preview-click="onPreviewClick"
                    ></image-library>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
        <v-col cols="12" class="py-0" order="8">
          <!-- ADDITIONAL INFO -->
          <v-card flat tile>
            <v-toolbar dense flat :color="disabled ? 'grey lighten-3 grey--text' : 'color1 color1Text--text'">
              <v-toolbar-title>
                Additional Info
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <html-editor v-model="vblEvent.brochureHTML" :disabled="disabled"></html-editor>
            </v-card-text>
          </v-card>
        </v-col>
        <slot name="cols"></slot>
      </v-row>
      <!-- DELETE -->
      <delete-cancel-dialog
        :tournament="vblEvent"
        mode="Delete"
        :isEvent="true"
        v-if="vblEvent.id > 0"
      ></delete-cancel-dialog>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DateTimePicker from '@/components/Utils/DateTimePicker.vue'
import ImageLibrary from '@/components/Organization/OrganizationImageLibrary.vue'
import HtmlEditor from '@/components/Utils/HtmlEditor.vue'
const DeleteCancelDialog = () => import('@/components/Tournament/Edit/DeleteCancelDialog.vue')

export default {
  props: ['vblEvent', 'btnOffset', 'disabled', 'loading'],
  data () {
    return {
      coverPhotoI: 0,
      selectedPage: this.user && this.user.pages ? this.user.pages[0] : null,
      backPositions: [
        'left top',
        'left center',
        'left bottom',
        'right top',
        'right center',
        'right bottom',
        'center top',
        'center center',
        'center bottom'
      ],
      publishing: false
    }
  },
  computed: {
    ...mapGetters([
      'user'
    ]),
    coverPhoto () {
      return this.vblEvent.coverPhotos[this.coverPhotoI]
    },
    organizations () {
      return this.user.pages.map((p) => {
        return {
          id: p.id,
          name: p.name,
          username: p.username
        }
      })
    },
    selectedUsername () {
      return this.selectedPage && this.selectedPage.username
    },
    oneDay () {
      return this.vblEvent._dtEnd === this.vblEvent._dtStart
    },
    published: {
      get () {
        return this.vblEvent.isPublic
      },
      set (val) {
        this.vblEvent.isPublic = val
        this.togglePublish(val)
      }
    }
  },
  methods: {
    onPreviewClick (image) {
      this.selectedImage = image
      if (!this.coverPhoto) {
        this.vblEvent.coverPhotos.push({
          id: image.id,
          url: image.url
        })
        return
      }
      this.coverPhoto.id = image.id
      this.coverPhoto.url = image.url
    },
    deletePhoto (i) {
      if (i === 0) return
      this.vblEvent.coverPhotos.splice(i, 1)
      this.coverPhotoI = i - 1
    },
    selectOrg () {
      if (this.user && this.user.pages.length) this.selectedPage = this.user.pages[0]
    },
    toggleOneDay () {
      if (this.oneDay) {
        this.vblEvent.dtEnd = this.vblEvent.dtStart.add(1, 'days').format('YYYY-MM-DD')
      } else {
        this.vblEvent.dtEnd = this.vblEvent.dtStart
      }
    },
    togglePublish (val) {
      const dto = {
        id: this.vblEvent.id,
        isPublic: val
      }
      this.publishing = true
      this.$VBL.post.series(dto)
        .then(r => {
          this.vblEvent.isPublic = dto.isPublic
          this.$emit('changed', r.data)
        })
        .catch(e => {
          console.log(e.response)
          this.vblEvent.isPublic = !dto.isPublic
        })
        .finally(() => {
          this.publishing = false
        })
    }
  },
  watch: {
    user: function (n, o) {
      this.selectOrg()
    }
  },
  mounted () {
    this.selectOrg()
  },
  components: {
    DateTimePicker,
    HtmlEditor,
    ImageLibrary,
    DeleteCancelDialog
  }
}
</script>

<style>
  .styledArea textarea {
    border-bottom: 2px solid #ccc;
  }
  .styledArea textarea:focus {
    border-bottom: 2px solid rgb(21, 42, 105);
  }
  .ql-editor p {
    margin-bottom: 16px;
  }
  .ql-align-center {
    text-align: center;
  }
  .ql-align-right {
    text-align: right;
  }
  .quill-editor {
    z-index: 0;
  }
</style>
